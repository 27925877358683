import { LiveEventCreate } from '../components/Event/Create';

function EventsCreateRoute() {
  return <LiveEventCreate />;
}

export const Component = EventsCreateRoute;

export function clientLoader() {
  return null;
}
